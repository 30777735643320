<template>
    <div v-if="isLoggedIn" style="min-height: 850px !important; background-color: #f5f6fa;"> 
    <!-- <div>  -->
      <flash-message transitionIn="animated swing" class="alert-system"></flash-message>
      <nav class="navbar navbar-expand navbar-light fixed-top">
    <div class="container"> 
      <router-link to="/dashboard" class="nav-item" style="color: #0f6f9b; text-decoration: none;"><a class="navbar-brand text-primary">
        <img class="img-fluid d-block" src="/img/logo.png" width="160px">
      </a></router-link>
      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto text-secondary"><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
          <!-- Nav Item - Search Dropdown (Visible Only XS) -->
        </a>
        <li class="nav-item dropdown no-arrow" style=""><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
          </a>
        </li>
        <!-- Nav Item - Alerts -->
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow text-secondary">
          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 small text-secondary"> {{ user.FullName.split(" ")[0] }} </span>
            <i class="fa fa-user-o fa-fw text-secondary"></i>
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <router-link to="/dashboard/cadastro" class="nav-item"> <a class="dropdown-item text-secondary">
              <i class="fa fa-user-o fa-sm fa-fw mr-2"></i> Seu cadastro </a> </router-link>
            <router-link to="/dashboard/cadastro" class="nav-item"> <a class="dropdown-item text-secondary" href="dashboard/cadastro">
              <i class="fa fa-key fa-sm fa-fw mr-2"></i> Alterar senha </a> </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-secondary" href="#" data-toggle="modal" data-target="#logout">
              <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
<!--menu inferior-->
  <nav class="navbar navbar-expand flex-row fixed-bottom d-flex justify-content-around navbar-dark bg-primary py-1">
    <div class="flex-row d-flex justify-content-around mx-auto" id="navbar3">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item mx-100p text-center"> 
          <a class="nav-link" href="/dashboard"><i class="fa fa-fw fa-home text-white fa-lg"></i>
            <p class="small text-white mb-0">Home</p>
          </a>
        </li>
        <li class="nav-item mx-100p text-center">
          <button @click="contractsPending()" class="nav-link"><i class="fa fa-fw fa-edit text-white fa-lg"></i>
            <p class="small text-white mb-0">Contratos</p>
          </button> </li>
        <li class="nav-item mx-100p text-center"> 
          <button @click="verBoletos()" class="nav-link"><i class="fa fa-fw fa-file-text-o text-white fa-lg"></i><br>
            <p class="small text-white mb-0">Meus Acordos</p>
          </button> </li>
        <!-- <li class="nav-item mx-100p text-center"> <a class="nav-link" href="https://api.whatsapp.com/send?l=pt&amp;phone=5514000000000" target="_blank"><i class="fa fa-whatsapp fa-fw text-white fa-lg"></i>
            <p class="small text-white mb-0">WhatsApp</p>
          </a> </li> -->
      </ul>
    </div>
  </nav>
    <router-view></router-view>

      <div class="modal fade mt-5" id="logout">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title text-primary">Logout</h6> <button type="button" class="close" data-dismiss="modal"> <span>×</span> </button>
        </div>
        <div class="modal-body">
          <h6>Deseja encerrar a sessão?</h6>
        </div>
        <div class="modal-footer"> <a href="#" @click="this.ActionDoLogout" class="btn btn-dark px-5">Sair</a> <button type="button" class="btn btn-outline-dark" data-dismiss="modal">Voltar</button> </div>
      </div>
    </div>
  </div>
   </div>
    

</template>

<script>

//import UserMenu from './components/UserMenu';
import { mapState } from "vuex";
import { mapActions } from "vuex";
import {
  formatDocument,
} from "@/utils/helpers";

export default {
    name: "Dashboard",
    components: {
        //UserMenu
    },
    data: () => ({

    }),
    async created() {
    },
    methods: {
      formatDocument,
        ...mapActions('auth', ['ActionDoLogout','ActionMenuLinks','ActionGetUser','ActionGetWhatsText']),

        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
          });
        },

        contractsPending() {
            this.$router.push({
              name: "meusContratos",
            });
          
        },

        
    },
    computed:{ 
        ...mapState('auth', ['user']),
        isLoggedIn(){ 
            return this.$store.getters['auth/isLoggedIn']
        } 
    }
};
</script>
<style>
#blue, a {
  color: #1641cc;
}


</style>
